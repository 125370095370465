import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import Subheader from '../components/Subheader';
import Form from '../components/Form';

const Page = ({ data }) => (
  <Layout
    title={data.datoCmsFixtext.seo && data.datoCmsFixtext.seo.title}
    description={data.datoCmsFixtext.seo && data.datoCmsFixtext.seo.description}
  >
    <div className="common-page">
      <Subheader title="KONTAKT" />

      <div className="container">
        <div className="row">
          <div className="page-contact">
            <p>Bildungsverein #offenegesellschaft</p>
            <p>
              Gumpendorfer Straße 132 1/1 <br />
              1060 Wien <br />
              T: +43 (0) 676 848843400
              <br />
              M: post@bvog.at
            </p>

            <Form subjectName="Kontaktformular" />
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default Page;

export const query = graphql`
  query ContactsQuery {
    datoCmsFixtext(name: { eq: "Contacts" }) {
      fixtext
      introtext
      seo {
        title
        description
      }
    }
  }
`;
