import React from 'react';

import Video from './video';
import Text from './text';
import Audio from './audio';
import Veranstaltung from './veranstaltung';
import Schulung from './schulung';
import Check from './check';

export const IconVideo = () => <Video />;
export const IconText = () => <Text />;
export const IconAudio = () => <Audio />;
export const IconVeranstaltung = () => <Veranstaltung />;
export const IconSchulung = () => <Schulung />;
export const IconCheckMark = () => <Check />;
